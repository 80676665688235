<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img style="width: 150px" fluid :src="imgUrl" alt="Login V2" />
          <!--
          <h2 class="brand-text text-primary ml-1">
            Vuexy
          </h2>-->
        </b-link>

        <b-card-title class="mb-1">
          Bienvenido a Serví Mensajería Tavo
        </b-card-title>
        <b-card-text class="mb-2">
          Inicia sesión
        </b-card-text>

        <validation-observer ref="loginValidation">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <b-form-group label="Email" label-for="login-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="hola@email.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">Contraseña</label>
                <b-link :to="{ name: 'send-email-forgot-password' }">
                  <small>¿ Olvidaste tu contraseña ?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-overlay :show="loading_btn" rounded="sm">
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Entrar
              </b-button>
            </b-overlay>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  // BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BOverlay,
  BCard
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import authService from "@/services/auth.service";

export default {
  components: {
    BCard,
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    // BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading_btn: false,
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login/logo_tavo.png"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      showRegisterModal: false
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login/logo_tavo.png");
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  methods: {
    register() {
      this.showRegisterModal = true;
    },
    executeRegister() {
      this.showRegisterModal = false;
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(async success => {
        if (success) {
          try {
            this.loading_btn = true;
            const { data: res } = await authService.login({
              email: this.userEmail,
              password: this.password
            });

            if (!res.success) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Verifica los datos de acceso.",
                  icon: "CheckIcon",
                  variant: "danger"
                }
              });
              this.loading_btn = false;
              return;
            }
            console.log(res.data.user);
            localStorage.setItem("role", res.data.user.role);
            localStorage.setItem("token", res.data.access_token);
            this.$store.dispatch("app/update_user", res.data);
            this.$store.dispatch("app/getGeneralData");
            await new Promise(resolve => setTimeout(resolve, 1000));

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Sesión iniciada correctamente",
                icon: "CheckIcon",
                variant: "success"
              }
            });
            this.$router.push("/dashboard");

            this.loading_btn = false;
          } catch (e) {
            console.log(e);
            this.loading_btn = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Ha ocurrido un error interno",
                icon: "AlertCircleIcon",
                variant: "danger"
              }
            });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
